<template>
  <div class="shop-search-hero">
    <form
      type="submit"
      class="shop-search-input-text__form"
      @submit.prevent="submit"
    >
      <div class="shop-search-input__wrapper">
        <FormInputText
          class="shop-search-input-text__input"
          :data="{
            field_key: 'searchString',
            label: data.field_label,
            is_required: false,
          }"
          :prefill-value="data.prefill_value"
          :is-auto-complete="true"
          @emit-value="setData"
        />

        <AppIcon
          class="shop-search-input-text__icon"
          icon="search"
          :is-raw="true"
          @click="submit"
        />
      </div>

      <AtomButton
        button-type="submit"
        :text="data.button_text"
      />
    </form>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        required: true,
    },
});

const inputData = ref('');
const setData = (data) => {
    inputData.value = data.value;
};

const shopBaseUrl = computed(() => props.data?.shop_link?.cached_url?.slice(0, -1));
const submit = () => {
    navigateTo(`${shopBaseUrl.value}?search=${inputData.value}`);
};
</script>

<style lang="scss" scoped>
.shop-search-hero {
    margin-top: 40px;
}

.shop-search-input-text {
    @include grid-columns(1, var(--grid-columns));
    position: relative;
    display: flex;
    width: 100%;
    max-width: 537px;
    margin-bottom: 10px;

    &.is-hidden {
        display: none;
    }
}

.shop-search-input-text__icon {
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 20px;
    transform: translateY(-50%);
}

.shop-search-input-text__input {
    width: 100%;
    margin: 0;

}

.shop-search-input-text__form {
    position: relative;
    display: flex;
    width: 100%;
    column-gap: 20px;
    row-gap: 20px;

    @include tablet {
        flex-wrap: wrap;
    }
}

.shop-search-input__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    height: unset;

    .form-input-text {
        margin-bottom: 0;
    }
}
</style>
